import React from 'react';
import { useI18nContext } from '@ecg-marktplaats/js-react-i18n';
import { Link } from '@hz-design-system/web-ui';
import Classes from './LoginTabs.scss';

type TLoginTabsProps = {
  isLoginActive?: boolean;
  isCreateAccountActive?: boolean;
};

const LoginTabs = ({ isLoginActive = false, isCreateAccountActive = false }: TLoginTabsProps) => {
  const { t } = useI18nContext();

  return (
    <div className={Classes.root}>
      <Link href="/identity/v2/login" className={Classes.tab} data-active={isLoginActive}>
        {t('pages.login.login_tab')}
      </Link>
      <Link href="/identity/v2/create-account" className={Classes.tab} data-active={isCreateAccountActive}>
        {t('pages.create_account.create_account_tab')}
      </Link>
    </div>
  );
};

export default LoginTabs;
