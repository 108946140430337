const ATTRIBUTES = {
  TRACK_EVENT: 'data-ga-track-event',
};

const gAEventOnce = new Set();

/**
 * Tracks event in GA
 */
const trackGAEvent = (eventAction: string, eventLabel: string = '') => {
  if (eventAction) {
    window.ecGa('trackEvent', { eventAction, eventLabel });
  }
};

const trackGAEventOnce = (eventAction: string, eventLabel: string = '') => {
  if (!gAEventOnce.has(eventAction + eventLabel)) {
    gAEventOnce.add(eventAction + eventLabel);
    trackGAEvent(eventAction, eventLabel);
  }
};

/**
 * Returns attribute to enable tracking the click in GA
 * Apply for links in app-shell that are not rehydrated client-side
 */
const getGATrackingAttrForSSR = (action: string, label: string): object => ({
  [ATTRIBUTES.TRACK_EVENT]: `${encodeURIComponent(action)},${encodeURIComponent(label)}`,
});

export { getGATrackingAttrForSSR, trackGAEvent, trackGAEventOnce };
