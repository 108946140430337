import React, { useRef, useEffect, useState } from 'react';
import { TToastNotificationProps } from '../components/Toast/ToastNotification';

function useToastNotification<T>() {
  const toastNotification = useRef<((props: TToastNotificationProps) => void) | any>(null);
  const [toast, setToast] = useState<React.FC<T> | null>(null);

  useEffect(() => {
    const loadComponent = async () => {
      try {
        toastNotification.current = (
          await import(/* webpackChunkName: "toastNotification" */ '../components/Toast/ToastNotification')
        ).default;
        setToast(() => toastNotification?.current);
      } catch (err) {}
    };
    if (!toast) {
      loadComponent();
    }
  }, [toast]);

  return {
    toast,
  } as {
    toast: React.FC<T>;
  };
}

export default useToastNotification;
