// i18n
import { i18n } from '@ecg-marktplaats/js-react-i18n';
import dateLocale from 'date-fns/locale/nl';
import translation from '../../../../locales/nl-NL/translation.json';
import common from '../../../../locales/nl-NL/common.json';

export default i18n.init({
  lng: 'nl-NL',
  translations: {
    translation,
    common,
  },
  dateLocale,
});
