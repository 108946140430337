import React, { useState, useEffect } from 'react';
import loadScript from '../utils/loadScript';

type TUseGoogleLoginProps = {
  googleSdkUrl: string;
  googleClientID: string;
  onSuccess: Function;
  onError?: Function;
  buttonRef: React.RefObject<HTMLDivElement>;
};

type TUseGoogleLogin = [boolean];

const useGoogleLogin = ({
  googleSdkUrl,
  googleClientID,
  buttonRef,
  onSuccess,
}: TUseGoogleLoginProps): TUseGoogleLogin => {
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    setIsProcessing(true);
    const loadAndInitGoogleScript = async () => {
      try {
        await loadScript({ url: googleSdkUrl, id: 'googleSdkScript' });

        google.accounts.id.initialize({
          // eslint-disable-next-line
          client_id: googleClientID,
          callback: (response: any) => onSuccess(response.credential),
        });
        google.accounts.id.renderButton(buttonRef.current, {
          theme: 'filled_blue',
          size: 'medium',
          text: 'continue_with',
          // eslint-disable-next-line
          use_fedcm_for_prompt: true,
        });

        google.accounts.id.prompt();
      } catch (e) {
        setIsProcessing(false);
      }
    };
    loadAndInitGoogleScript();
    // eslint-disable-next-line
  }, [googleSdkUrl, googleClientID]);

  return [isProcessing];
};

export default useGoogleLogin;
