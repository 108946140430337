import React from 'react';

import { EnvironmentProvider } from '../../contexts/EnvironmentContext';

import LoginComponent from './components/LoginComponent';

type TLoginAppProps = {
  appState?: any;
  environment: any;
};

const LoginApp = ({ /*appState,*/ environment }: TLoginAppProps) => (
  <EnvironmentProvider value={environment}>
    <LoginComponent />
  </EnvironmentProvider>
);

export default LoginApp;
