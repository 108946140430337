type TLoadScript = {
  url?: string;
  id: string;
  innerHTML?: string;
  callback?: Function;
  onErrorCallback?: Function;
};

const loadScript = ({ url, id, innerHTML }: TLoadScript): Promise<void> =>
  new Promise((resolve, reject) => {
    const tagName = 'script';
    const firstJavaScriptTag = document.getElementsByTagName(tagName)[0];
    const existingScript = document.getElementById(id);

    if (existingScript) {
      resolve();
    } else {
      const script = document.createElement(tagName);
      script.id = id;
      if (url) {
        script.src = url;
      }
      if (innerHTML) {
        script.innerHTML = innerHTML;
      }

      firstJavaScriptTag.parentNode?.insertBefore(script, firstJavaScriptTag);

      script.onload = () => {
        resolve();
      };

      script.onerror = () => {
        reject();
      };
    }
  });

export default loadScript;
