import fetchAPI from '../../../utils/fetchAPI';

type TSocialLoginProps = {
  xsrfToken: string;
  accessToken: string;
  threatMetrix: any;
  socialNetwork: string;
  firstTimeSocialLoginUrl?: string;
};

export default function socialLogin({
  accessToken,
  socialNetwork,
  threatMetrix,
  xsrfToken,
}: TSocialLoginProps): Promise<any> {
  return fetchAPI({
    method: 'POST',
    url: '/identity/v2/api/social/login',
    xsrfToken,
    payload: { accessToken, socialNetwork, threatMetrix },
  });
}
