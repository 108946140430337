import React from 'react';
import { Header } from '@hz-design-system/web-ui';
import type { THeaderConfig } from '@hz-design-system/web-ui/lib/types/THeaderConfig';
import { createRoot, hydrateRoot } from 'react-dom/client';
import { I18nContextProvider } from '@ecg-marktplaats/js-react-i18n';

import '../../../scss/global.scss';

import { trackGAEvent } from '../../utils/gaTracking';

import LoginApp from '../../apps/LoginApp/LoginApp';

export default (i18n: any) => {
  // eslint-disable-next-line no-underscore-dangle
  const environment = window.__ENV_CONFIG__ || {};
  const headerConfig: THeaderConfig = window.__HEADER_CONFIG__ || ({} as THeaderConfig);

  const headerContainer = document.getElementById('header-root');
  const loginRoot = createRoot(document.getElementById('login-app-root')!);

  // Header is server side rendered in express-react library
  hydrateRoot(headerContainer!, <Header headerConfig={headerConfig} trackGAEvent={trackGAEvent} />);

  loginRoot.render(
    <I18nContextProvider i18nInstance={i18n}>
      <LoginApp environment={environment} />
    </I18nContextProvider>,
  );
};
