import React, { useContext, useRef, useState } from 'react';

import EnvironmentContext from '../../contexts/EnvironmentContext';
import { trackGAEvent } from '../../utils/gaTracking';
import socialLogin from './repositories/socialLogin';
import useGoogleLogin from '../../hooks/useGoogleLogin';

import Classes from './LoginGoogleButton.scss';
import { ClientError } from '@ecg-marktplaats/aurora-node-api-client';
import {
  USER_NOT_FOUND,
  CONFIRMATION_REQUIRED,
  GA_EVENTS,
  TWO_FACTOR_AUTH_SETUP_REQUIRED,
  TWO_FACTOR_AUTH_NEEDED,
  FORCE_PASSWORD_RESET,
} from '../../../../common/constants';
import { DialogModal } from '@hz-design-system/web-ui';
import { useI18nContext } from '@ecg-marktplaats/js-react-i18n';
import useToastNotification from '../../hooks/useToastNotification';
import type { TToastNotificationProps } from '../../components/Toast/ToastNotification';

type TLoginGoogleButtonProps = {
  onClick?: Function;
  onError?: Function;
  onSuccess?: Function;
};

const LoginGoogleButton = ({ onError, onSuccess }: TLoginGoogleButtonProps) => {
  const { t, tExists } = useI18nContext();
  const { toast } = useToastNotification<TToastNotificationProps>();
  const [isOpen, setIsOpen] = useState(false);
  const [createAccountUrl, setCreateAccountUrl] = useState('');
  const buttonRef = useRef(null);
  const {
    social: { googleSdkUrl, googleClientID },
    threatMetrix,
    xsrfToken,
    successUrl = '/',
  } = useContext(EnvironmentContext);

  const handleSuccess = async (accessToken: string) => {
    try {
      trackGAEvent(GA_EVENTS.SocialLoginBegin, 'Google');
      const res = await socialLogin({
        accessToken,
        socialNetwork: 'Google',
        threatMetrix,
        xsrfToken,
      });
      if (res?.success) {
        trackGAEvent(GA_EVENTS.SocialLoginSuccess, 'Google');
        window.location.assign(successUrl);
      }
    } catch (error) {
      trackGAEvent(GA_EVENTS.SocialLoginFail, 'Google');
      const errorMessage = (error as ClientError).message;
      switch (errorMessage) {
        case TWO_FACTOR_AUTH_SETUP_REQUIRED:
          window.location.assign('/identity/v2/two-factor-auth-setup');
          break;
        case TWO_FACTOR_AUTH_NEEDED:
          window.location.assign('/identity/v2/two-factor-auth-setup/challenge');
          break;
        case FORCE_PASSWORD_RESET:
          window.location.assign('/identity/v2/reset-password');
          break;
        case CONFIRMATION_REQUIRED:
          window.location.assign('/identity/v2/confirm');
          break;
        case USER_NOT_FOUND:
          setIsOpen(true);
          setCreateAccountUrl(`/identity/v2/create-account?accessToken=${accessToken}`);
          break;
        default:
          const errorMsg = errorMessage?.toLowerCase();
          const errorText = tExists(`pages.login.validation.${errorMsg}`);
          if (errorText) {
            toast({ type: 'error', description: t(`pages.login.validation.${errorMsg}`) });
          } else {
            toast({ type: 'error', description: t('pages.login.validation.unknown_server_error') });
          }
          if (onSuccess) {
            onSuccess();
          }
      }
    }
  };

  useGoogleLogin({
    googleSdkUrl,
    googleClientID,
    onSuccess: handleSuccess,
    onError: onError,
    buttonRef,
  });

  return (
    <>
      <div className={Classes.socialButton} ref={buttonRef} id="google-signin-button" />
      {isOpen && (
        <DialogModal
          title={t('pages.create_account.confirm_dialog.title')}
          cancel={{
            text: t('pages.create_account.confirm_dialog.cancel_button'),
            onClick: () => setIsOpen(false),
          }}
          confirm={{
            text: t('pages.create_account.confirm_dialog.confirm_button'),
            onClick: () => {
              setIsOpen(false);
              window.location.assign(createAccountUrl);
            },
          }}
          onClose={() => setIsOpen(false)}
        >
          <p>{t('pages.create_account.confirm_dialog.content')}</p>
        </DialogModal>
      )}
    </>
  );
};

export default LoginGoogleButton;
