import React from 'react';

import { TEnvironmentContext } from 'types/EnvironmentContext';

const defaultValues: TEnvironmentContext = {
  staticBaseUrl: '',
  xsrfToken: '',
  tenantContext: {
    tenat: 'mp',
    locale: 'nl',
  },
  baseUrl: '',
  links: {
    expiredTokenRedirectUrl: '',
    homePageRedirectUrl: `/`,
    validatedTargetUrl: '',
    loginRedirectUrl: '',
    tmxScriptUrl: '',
    tmxIframeUrl: '',
  },
  social: {
    googleSdkUrl: '',
    googleClientID: '',
  },
  threatMetrix: {
    timestamp: 1,
    signature: '',
    uuid: '',
    domain: '',
    pageID: 11,
  },
  reblaze: {
    challengeUrl: '',
  },
  expired: false,
  force: false,
  defaultCountryCode: 'NL',
  twoFactorAuthSetupChange: false,
  twoFactorAuthSetupVerify: false,
  tamperedRecoveryPhoneNumber: '',
  is2FAEnabled: false,
  userDetails: {},
  emailNewsletterOptin: false,
  recaptchaScriptUrl: '',
  mpEmbeddedInMobileApp: false,
  username: '',
  email: '',
  recaptchaSiteKey: '',
};

const EnvironmentContext = React.createContext(defaultValues);

export const EnvironmentProvider = EnvironmentContext.Provider;
export const EnvironmentConsumer = EnvironmentContext.Consumer;

export default EnvironmentContext;
