import fetchAPI from '../../../utils/fetchAPI';

type TParams = {
  email: string;
  password: string;
  rememberMe: boolean;
  xsrfToken: string;
  recaptchaToken?: string;
  threatMetrix: any;
};

export default function loginSubmit({
  email,
  password,
  rememberMe,
  xsrfToken,
  recaptchaToken,
  threatMetrix,
}: TParams): Promise<any> {
  return fetchAPI({
    method: 'POST',
    url: '/identity/v2/api/login',
    xsrfToken,
    payload: { email, password, rememberMe, recaptchaToken, threatMetrix },
  });
}
