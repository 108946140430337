import { useEffect, useState } from 'react';
import loadScript from '../utils/loadScript';

const useReblaze = (reblazeChallengeUrl?: string) => {
  const [hasReblazeChallengeScript, setHasReblazeChallengeScript] = useState(false);

  useEffect(() => {
    if (!hasReblazeChallengeScript && reblazeChallengeUrl) {
      const loadThreatMetrixScript = async () => {
        try {
          await loadScript({
            url: reblazeChallengeUrl,
            id: 'profilingScript',
          });
          setHasReblazeChallengeScript(true);
        } catch (e) {
          console.error('Failed to load ReblazeChallenge script', e);
        }
      };

      loadThreatMetrixScript();
    }
  }, [hasReblazeChallengeScript, reblazeChallengeUrl]);

  return hasReblazeChallengeScript;
};

export default useReblaze;
